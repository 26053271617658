import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
import { Link as LinkGatsby } from "gatsby"

import { Center, VStack } from "@chakra-ui/react";
import { Box, Heading, Button } from '@chakra-ui/react';
import {
  ArrowForwardIcon
} from '@chakra-ui/icons';

const NotFoundPage = () => (


  <Layout>
    <Seo title="404: Página No Encontrada" />
    <Center alignItems={"flex-start"} h="100vh" pt={8}>
      <VStack textAlign="center" py={0} px={'xl'}>
        <StaticImage style={{ height: '50%', objectFit: 'scale-down' }} src="../assets/404.svg" alt="Marketing Dental" />

        <Box>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            ¡Opps! Página no encontrada.
          </Heading>
          <Button as={LinkGatsby} to="/" rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline'>
            Ir a Inicio
          </Button>
        </Box>


      </VStack>
    </Center>
  </Layout>
)

export default NotFoundPage

